/*! instantsearch.js 2.5.1 | © Algolia Inc. and other contributors; Licensed MIT | github.com/algolia/instantsearch.js */
.ais-clear-all--link, .ais-current-refined-values--clear-all, .ais-price-ranges--button, .ais-range-input--submit {
  color: #FFFFFF;
  display: inline-block;
  background: #3369E7;
  border-radius: 4px;
  font-size: 12px;
  text-decoration: none;
  padding: 4px 8px; }
  .ais-clear-all--link:hover, .ais-current-refined-values--clear-all:hover, .ais-price-ranges--button:hover, .ais-range-input--submit:hover {
    text-decoration: none;
    color: #FFFFFF;
    background: #184ECD; }

.ais-clear-all--link-disabled, .ais-current-refined-values--clear-all-disabled {
  opacity: .5;
  pointer-events: none; }

.ais-current-refined-values--clear-all {
  margin-bottom: 5px; }

.ais-current-refined-values--item, .ais-hierarchical-menu--item, .ais-menu--item, .ais-refinement-list--item, .ais-star-rating--item, .ais-toggle--item {
  font-size: 14px;
  line-height: 30px; }

.ais-current-refined-values--link, .ais-hierarchical-menu--link, .ais-menu--link, .ais-refinement-list--label, .ais-toggle--label, .ais-price-ranges--link, .ais-star-rating--link {
  color: #3E82F7;
  text-decoration: none; }
  .ais-current-refined-values--link:hover, .ais-hierarchical-menu--link:hover, .ais-menu--link:hover, .ais-refinement-list--label:hover, .ais-toggle--label:hover, .ais-price-ranges--link:hover, .ais-star-rating--link:hover {
    color: #184ECD;
    text-decoration: none; }

.ais-current-refined-values--count, .ais-hierarchical-menu--count, .ais-menu--count, .ais-refinement-list--count, .ais-star-rating--count, .ais-toggle--count {
  background: rgba(39, 81, 175, 0.1);
  border-radius: 31px;
  color: #3E82F7;
  padding: 2px 10px; }

.ais-hierarchical-menu--item__active > div > .ais-hierarchical-menu--link {
  font-weight: bold; }
  .ais-hierarchical-menu--item__active > div > .ais-hierarchical-menu--link::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

.ais-hierarchical-menu--link {
  position: relative; }
  .ais-hierarchical-menu--link::after {
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 8 13' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 1.5l5 4.98-5 5.02' stroke='%23697782' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round' opacity='.4'/></svg>") no-repeat center center/contain;
    content: ' ';
    display: block;
    position: absolute;
    top: calc(50% - 14px / 2);
    right: -22px;
    height: 14px;
    width: 14px; }

select.ais-hits-per-page-selector, select.ais-numeric-selector, select.ais-sort-by-selector, select.ais-menu-select--select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: #FFFFFF url("data:image/svg+xml;utf8,<svg viewBox='0 0 12 7' xmlns='http://www.w3.org/2000/svg'><path d='M11 1L6.02 6 1 1' stroke-width='1.5' stroke='%23BFC7D8' fill='none' fill-rule='evenodd' stroke-linecap='round'/></svg>") no-repeat center right 16px/10px;
  -webkit-box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2) !important;
          box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2) !important;
  border: solid 1px #D4D8E3 !important;
  border-radius: 4px;
  color: #697782;
  font-size: 12px;
  -webkit-transition: background 0.2s ease, -webkit-box-shadow 0.2s ease;
  transition: background 0.2s ease, -webkit-box-shadow 0.2s ease;
  transition: background 0.2s ease, box-shadow 0.2s ease;
  transition: background 0.2s ease, box-shadow 0.2s ease, -webkit-box-shadow 0.2s ease;
  padding: 8px 32px 8px 16px;
  outline: none; }

.ais-menu--item__active > div > .ais-menu--link {
  font-weight: bold; }

.ais-menu button {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 11px; }

.ais-refinement-list--item {
  line-height: 24px; }
  .ais-refinement-list--item__active > div > .ais-refinement-list--label, .ais-refinement-list--item__active > div > .ais-toggle--label {
    font-weight: bold; }

.ais-refinement-list--label, .ais-toggle--label {
  cursor: pointer; }
  .ais-refinement-list--label input[type="radio"], .ais-toggle--label input[type="radio"],
  .ais-refinement-list--label input[type="checkbox"], .ais-toggle--label input[type="checkbox"] {
    margin-right: 5px; }

.ais-refinement-list div > button {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-size: 11px; }

.ais-pagination {
  background: #FFFFFF;
  -webkit-box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2);
          box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  border: solid 1px #D4D8E3;
  border-radius: 4px;
  display: inline-block;
  padding: 8px 16px;
  width: auto; }
  .ais-pagination--item {
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    width: 28px; }
    .ais-pagination--item:hover {
      background: rgba(39, 81, 175, 0.1); }
    .ais-pagination--item__disabled {
      color: #BBB;
      opacity: .5;
      pointer-events: none;
      visibility: visible; }
    .ais-pagination--item__active {
      background: #3369E7; }
      .ais-pagination--item__active .ais-pagination--link {
        color: #FFFFFF; }
  .ais-pagination--link {
    color: #697782;
    display: block;
    text-decoration: none;
    width: 100%; }
    .ais-pagination--link:hover {
      color: #3369E7;
      text-decoration: none; }

.ais-price-ranges--item {
  font-size: 14px;
  line-height: 24px; }
  .ais-price-ranges--item__active {
    font-weight: bold; }

.ais-price-ranges--form {
  margin-top: 10px; }

.ais-price-ranges--input {
  background: #FFFFFF;
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(85, 95, 110, 0.2);
          box-shadow: inset 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  border: solid 1px #D4D8E3;
  border-radius: 4px;
  outline: none; }

.ais-price-ranges--button {
  border: 0;
  outline: none;
  margin-left: 5px;
  position: relative;
  top: -2px; }

.ais-range-input--inputMin, .ais-range-input--inputMax {
  background: #FFFFFF;
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(85, 95, 110, 0.2);
          box-shadow: inset 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  border: solid 1px #D4D8E3;
  border-radius: 4px;
  outline: none; }

.ais-range-input--submit {
  border: none;
  outline: none;
  margin-left: 5px;
  position: relative;
  top: -2px; }

.ais-range-slider--handle {
  border: 1px solid #3369E7; }

.ais-range-slider .rheostat-progress {
  background-color: #3369E7 !important; }

.ais-search-box {
  display: inline-block;
  position: relative;
  height: 46px;
  white-space: nowrap;
  font-size: 14px; }
  .ais-search-box--input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    font: inherit;
    background: #FFFFFF;
    display: inline-block;
    border: 1px solid #D4D8E3;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2);
            box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2);
    -webkit-transition: background .4s ease, -webkit-box-shadow .4s ease;
    transition: background .4s ease, -webkit-box-shadow .4s ease;
    transition: box-shadow .4s ease, background .4s ease;
    transition: box-shadow .4s ease, background .4s ease, -webkit-box-shadow .4s ease;
    padding: 10px 10px 10px 35px;
    vertical-align: middle;
    white-space: normal;
    height: 100%;
    width: 100%; }
    .ais-search-box--input:focus {
      -webkit-box-shadow: none;
              box-shadow: none;
      outline: 0; }
  .ais-search-box--reset {
    fill: #BFC7D8;
    top: calc(50% - 12px / 2);
    right: 13px; }
  .ais-search-box--magnifier {
    fill: #BFC7D8;
    left: 12px;
    top: calc(50% - 18px / 2); }
    .ais-search-box--magnifier svg {
      height: 18px;
      width: 18px; }
  .ais-search-box--loading-indicator-wrapper {
    fill: #BFC7D8;
    left: 12px;
    top: calc(50% - 18px / 2); }
    .ais-search-box--loading-indicator-wrapper svg {
      height: 18px;
      width: 18px; }

.ais-stats {
  color: #697782;
  font-size: 14px;
  opacity: .6; }

.ais-toggle--item__active {
  font-weight: bold; }

.ais-breadcrumb--root .ais-breadcrumb--label,
.ais-breadcrumb--root .ais-breadcrumb--separator,
.ais-breadcrumb--root .ais-breadcrumb--home {
  display: inline;
  color: #3369E7; }
  .ais-breadcrumb--root .ais-breadcrumb--label div,
  .ais-breadcrumb--root .ais-breadcrumb--separator div,
  .ais-breadcrumb--root .ais-breadcrumb--home div {
    display: inline; }

.ais-breadcrumb--root .ais-breadcrumb--disabledLabel {
  color: #444444;
  display: inline; }

.ais-breadcrumb--root .ais-breadcrumb--separator {
  position: relative;
  display: inline-block;
  height: 14px;
  width: 14px; }
  .ais-breadcrumb--root .ais-breadcrumb--separator::after {
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 8 13' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 1.5l5 4.98-5 5.02' stroke='%23697782' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round' opacity='.4'/></svg>") no-repeat center center/contain;
    content: ' ';
    display: block;
    position: absolute;
    top: 2px;
    height: 14px;
    width: 14px; }

[class^="ais-"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  [class^="ais-"] > *,
  [class^="ais-"] > *::after,
  [class^="ais-"] > *::before {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }

.ais-header {
  border-bottom: 2px solid #EEE;
  font-size: .8em;
  margin: 0 0 6px;
  padding: 0 0 6px;
  text-transform: uppercase; }

/*# sourceMappingURL=instantsearch-theme-algolia.css.map*/